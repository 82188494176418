import React from "react";
import Slider, { Settings } from 'react-slick';
import styled from 'styled-components';
import { StaticImage } from "gatsby-plugin-image";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const SliderWrapper = styled.div`
  width: calc(100% - 50px);

  .slick-prev:before,
  .slick-next:before {
    color: black;
  }
`

const SimpleSlider = () =>  {
    const settings = {
      autoPlay: true,
      arrows: true,
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1500,
      autoplaySpeed: 3000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (

      <Container>
        <SliderWrapper>
          <Slider {...settings}>
            <div className="text-center">
              <StaticImage src="../images/macromill.png" className=" w-60 "/>
              <h4 className="font-normal my-2">株式会社マクロミル様</h4>
              <p className="text-sm">社内システム開発コンサル<br />社内向けサイト構築等</p>
            </div>
            {/* <div className="text-center">
              <StaticImage src="../images/nihonzaidan.png" className=" w-60 "/>
              <h4 className="font-normal my-2">公益財団法人日本財団様</h4>
              <p className="text-sm">社内システム開発・保守・運用<br />寄付サイト構築等</p>
            </div> */}
            <div className="text-center">
              <StaticImage src="../images/mdv.png" className=" w-60 "/>
              <h4 className="font-normal my-2">メディカル・データ・ビジョン株式会社様</h4>
              <p className="text-sm">システムコンサルティング<br />医療ビックデータ基盤構築・関連システム開発<br />医療ビックデータ抽出・分析・レポート作成</p>
            </div>
            <div className="text-center">
              <StaticImage src="../images/asmarq.png" className=" w-60 "/>
              <h4 className="font-normal my-2">株式会社アスマーク様</h4>
              <p className="text-sm">アンケート会員管理システム<br />アンケート情報管理システム開発・保守運用</p>
            </div>
            <div className="text-center">
              <StaticImage src="../images/ABCompany.png" className=" w-60 "/>
              <h4 className="font-normal my-2">株式会社AB&Company様</h4>
              <p className="text-sm">スタイリスト管理システム開発・保守運用<br />ETLツール開発・保守運用</p>
            </div>
            <div className="text-center">
              <StaticImage src="../images/monipura.png" className=" w-60 "/>
              <h4 className="font-normal my-2">モニタリングプラス</h4>
              <p className="text-sm">［自社開発ツール］<br />Web監視・サイトチェックツール</p>
            </div>
            <div className="text-center">
              <StaticImage src="../images/nursecall.png" className=" w-60 "/>
              <h4 className="font-normal my-2">NuRseCal</h4>
              <p className="text-sm">［自社企画アプリ］<br />看護師のためのストレスマネジメントアプリ</p>
            </div>
          </Slider>
        </SliderWrapper>
      </Container>
    );
  }

  export default SimpleSlider;