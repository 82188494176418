import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import moment from "moment";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import SimpleSlider from "../components/slider";

const HomePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMdx.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="株式会社スタートアッププラス｜Webシステム開発、システム運用・保守サポート、ITコンサルタント" />
      <section id="eyecatch" 
        className="relative m-0 p-0 bg-topEyecatch bg-no-repeat bg-center bg-bottom bg-cover h-[400px] lg:h-[700px] "
      >
        <h1 className="typewriter w-screen font-Copperplate text-2xl sm:text-4xl lg:text-6xl pt-16 lg:pt-36 px-3 sm:px-10">Crazy and Professional</h1>
        <p className="text-base sm:text-xl lg:text-3xl p-3 sm:p-10">遊びも仕事も全力で取り組む<br />エンジニア集団です</p>
        <div className="absolute bottom-0 px-5">
          <StaticImage src="../images/s_nakamura.png" className="w-[47px] sm:w-[76px] lg:w-[110px] mx-1"/>
          <StaticImage src="../images/s_chiba.png" className="w-[23px] sm:w-[38px] lg:w-[52px] mx-1"/>
          <StaticImage src="../images/s_yaegashi.png" className="w-[25px] sm:w-[40px] lg:w-[60px] mx-1 "/>
          <StaticImage src="../images/s_kuwayama.png" className="w-[26px] sm:w-[43px] lg:w-[64px] mx-1 "/>
          <StaticImage src="../images/s_hirota.png" className="w-[23px] sm:w-[36px] lg:w-[53px] mx-2 "/>
          <StaticImage src="../images/s_nomura.png" className="w-[20px] sm:w-[32px] lg:w-[47px] mx-2 "/>
          <StaticImage src="../images/s_fujikawa.png" className="w-[23px] sm:w-[38px] lg:w-[55px] mx-2 mt-1"/>
          <StaticImage src="../images/s_fukushi.png" className="w-[26px] sm:w-[42px] lg:w-[58px] mx-1 mt-1"/>
          <StaticImage src="../images/s_tadao.png" className="w-[30px] sm:w-[48px] lg:w-[70px] mx-1 mt-1"/>
        </div>
      </section>
      <section id="ourVision" className="m-0 ">
        <h2 className="m-0 h-20 sm:h-32 balloon bg-neutral-800 text-white font-Copperplate text-center text-2xl sm:text-4xl p-5 sm:p-10">
          Our Vision
        </h2>
        <div className=" w-11/12 lg:w-[1000px] m-auto py-5 sm:py-10 bg-ourVisionS sm:bg-ourVision bg-no-repeat sm:bg-center bg-top bg-contain sm:bg-cover">
          <h3 className=" text-xl sm:text-3xl my-5">プロダクトを通じて<br />世の中との循環関係を築く</h3>
          <div className="h-12 sm:h-20"></div>
          <p className="mb-10 py-4 px-4 sm:py-10 sm:px-0 sm:text-xl bg-key-sand sm:bg-transparent">
            私たちは、人を起点に化学反応を起こし、<br />
            より価値の高いプロダクトを生み出します。<br />
            プロダクトは、多くの人とつながり、<br />
            もっと大きな化学反応を起こす。<br />
            この循環を繰り返して、加速し、増幅し、<br />
            私たちが提供する価値を大きくしていきます。
          </p>
          <Link to="/company"  class="w-44 my-10 flex justify-center border border-neutral-800 px-4 py-3 transition duration-300 ease-in-out hover:bg-key-sand">
            <span className=" ">COMPANY</span>
            <svg xmlns="http://www.w3.org/2000/svg" class="inline ml-2 w-6 stroke-current stroke" viewBox="0 0 24 24" fill="none" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
          </Link>
        </div>
      </section>
      <section id="ProductionAndDeliver" className="m-0 ">
        <h2 className="m-0 h-20 sm:h-32 balloon bg-neutral-800 text-white font-Copperplate text-center text-2xl sm:text-4xl p-6 sm:p-10">
          Production and Deliver
        </h2>

        <div className="relative Block0 bg-engineering bg-no-repeat bg-center bg-top bg-cover">
          <div className="
            absolute left-0 top-10
            h-[50px] md:h-[70px] 
            w-60 md:w-2/5
            py-2 px-2 md:py-4 md:px-6
            text-2xl md:text-3xl 
            font-Copperplate 
            text-center md:text-right 
            bg-key-sand 
          ">
            Engineering
          </div>
          <div className="pt-16 pb-24 w-11/12 lg:w-[1000px] min-h-[400px] mx-auto md:flex justify-end">
            <div className="mt-20 md:mt-0 md:w-1/2 text-white">
              <h3 className="text-lg md:text-xl mb-10 font-medium ">QCDを満たすシステムをデザインする</h3>
              <p>スタートアッププラスは、多くの経験・ノウハウを基に、クライアントの投資価値を最大化できるシステム開発を目指し、システム要望に適したQCDを満たすシステム構築をご提供します。</p>
              <div className="flex justify-end">
                <Link to="/service#Engineering"  class="w-44 mt-10 flex justify-center border border-white px-4 py-3 transition duration-300 ease-in-out hover:bg-key-sand">
                <span className=" ">VIEW MORE</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="inline ml-2 w-6 stroke-current stroke" viewBox="0 0 24 24" fill="none" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="relative Block1 -mt-32 bg-serverroom bg-no-repeat bg-center bg-top bg-cover">
          <div className="
            absolute left-0 top-28
            h-[50px] md:h-[70px] 
            w-60 md:w-2/5
            py-1 px-2 md:py-[0.75rem] md:px-6
            text-2xl md:text-3xl 
            leading-5 md:leading-5
            font-Copperplate 
            text-center md:text-right 
            bg-key-sand 
          ">
            Monitoring &<br /> Maintenance
          </div>
          <div className="pt-16 pb-24 w-11/12 lg:w-[1000px] min-h-[400px] mx-auto md:flex justify-end">
            <div className="mt-40 md:mt-20 md:w-1/2 text-white">
              <h3 className="text-lg md:text-xl mb-10 font-medium ">システムを保守し継続的に運用するあらゆるサポートを実施</h3>
              <p>弊社のご提供する監視・運用サービスは、モニタリングと課題の抽出、提案改善から改善アクションまで継続的に行い、成長を続けるクライアントのサービス基盤を安定的に支えていきます。</p>
              <div className="flex justify-end">
                <Link to="/service#Monitoring"  class="w-44 mt-10 flex justify-center border border-white px-4 py-3 transition duration-300 ease-in-out hover:bg-key-sand">
                <span className=" ">VIEW MORE</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="inline ml-2 w-6 stroke-current stroke" viewBox="0 0 24 24" fill="none" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="relative Block2 -mt-32 bg-products bg-no-repeat bg-center bg-top bg-cover">
          <div className="
            absolute left-0 top-28
            h-[50px] md:h-[70px] 
            w-60 md:w-2/5
            py-2 px-2 md:py-4 md:px-6
            text-2xl md:text-3xl 
            font-Copperplate 
            text-center md:text-right 
            bg-key-sand 
          ">
            Products
          </div>
          <div className="pt-16 pb-10 w-11/12 lg:w-[1000px] min-h-[400px] mx-auto md:flex justify-end">
            <div className="mt-40 md:mt-20 md:w-1/2 text-white">
              <h3 className="text-lg md:text-xl mb-10 font-medium ">自社開発のシステムやアプリで世の中に貢献する</h3>
              <p>スタートアッププラスは、常に新しい価値とサービスを提供し続けます。自社単独での開発だけでなく、他社とのパートナーシップや共同開発など様々な形でサービスを世に送り出しています。 </p>
              <div className="flex justify-end">
                <Link to="/product"  class="w-44 mt-10 flex justify-center border border-white px-4 py-3 transition duration-300 ease-in-out hover:bg-key-sand">
                <span className=" ">VIEW MORE</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="inline ml-2 w-6 stroke-current stroke" viewBox="0 0 24 24" fill="none" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="ourVision" className="m-0 ">
        <h2 className="m-0 h-20 sm:h-32 balloon bg-neutral-800 text-white font-Copperplate text-center text-2xl sm:text-4xl p-5 sm:p-10">
          Our Works & Customers
        </h2>
        <div className="w-11/12 lg:w-[1000px] m-auto py-10 md:py-20">
          <p className=" text-xl sm:text-2xl leading-relaxed text-center">
          スタートアップからエンタープライズ企業まで<br className="hidden sm:block"/>
          さまざまな企業規模・業界における業務システム、WEBシステムの<br className="hidden sm:block"/>
          開発経験を持ち、幅広い技術力とノウハウを蓄積しています。<br />
          </p>
        </div>
        <div className="w-11/12 m-auto mb-20">      
          <SimpleSlider />
        </div>
      </section>

      <section id="CTA" className="m-0 ">
        <div className="innerBorder bg-key-sand flex justify-between flex-col md:flex-row">
          <div className="w-full md:w-1/2 p-10 lg:p-16">
            <h2 className="text-2xl lg:text-3xl">システムでお困りのこと<br />スタートアッププラスに<br />ご相談ください</h2>
            <div className="flex justify-end">
              <Link to="/contactus" class="w-40 mt-4 flex justify-center border border-black p-2 z-10">
                <span className="text-sm">お問い合わせはこちら</span>
              </Link>
            </div>
          </div>
          <div className="w-full h-60 md:h-auto md:w-1/2 block bg-dog bg-no-repeat bg-center bg-right bg-cover "></div>
        </div>
      </section>

      <section id="news" className="m-0 ">
        <div className="pt-16 pb-10 w-11/12 lg:w-[1000px] min-h-[400px] mx-auto lg:flex justify-end">
          <div className="w-full lg:w-3/5 p-3">
            <h2 className="font-Copperplate text-2xl sm:text-4xl">News</h2>
            <div className="mt-4 border-b border-key-sand "></div>
            <ol style={{ listStyle: `none` }}>

              { posts.map(post => {
                const title = post.frontmatter.title || post.fields.slug

                return (
                  <li key={post.fields.slug}>
                    
                    <article
                      className="py-5 post-list-item sm:flex border-b border-key-sand "
                      itemScope
                      itemType="http://schema.org/Article"
                    >
                      <p className="w-full sm:w-1/5 mr-6">
                        {moment(post.frontmatter.date).format(`YYYY/MM/DD`)}
                      </p>
                      <h3 className="w-full sm:w-4/5 hover:text-key-sand hover:font-semibold">
                        <Link to={post.fields.slug} itemProp="url">
                          <span itemProp="headline">{title}</span>
                        </Link>
                      </h3>
                    </article>
                  </li>
                )

              })}

            </ol>

            <div className="flex justify-end">
              <Link to="/info" class="w-44 mt-10 flex justify-center border border-neutral-800 px-4 py-3 transition duration-300 ease-in-out hover:bg-key-sand">
              <span className=" ">VIEW MORE</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="inline ml-2 w-6 stroke-current stroke" viewBox="0 0 24 24" fill="none" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
              </Link>
            </div>

          </div>
          <div className="w-full lg:w-2/5 lg:pb-3 px-6">
            <Link to="/recruit">
              <div className=" relative bg-key-sand pl-16 pr-6 my-10">
                <StaticImage 
                  src="../images/s_kuwayama.png" 
                  className="bottom-0 -left-3 w-[64px]"
                  style={{position: "absolute"}}/>
                <h4 className="font-Copperplate text-xl sm:text-3xl py-3">Recruit</h4>
                <p className="pb-6">新しい技術に興味関心があり、向上心のある仲間を探しています！</p>
              </div>
            </Link>
            <Link to="/aboutus-data">
              <div className=" relative bg-key-sand pl-16 pr-6 my-10">
                <StaticImage src="../images/s_hirota.png" 
                  className="bottom-0 -left-1 w-[59px]"
                  style={{position: "absolute"}}/>
                <h4 className="font-Copperplate text-xl sm:text-3xl py-3">About us</h4>
                <p className="pb-6">スタートアッププラスについて<br />社内の雰囲気や社員の情報を公開</p>
              </div>
            </Link>
            <Link to="https://twitter.com/StartupPlus_Inc" target="_blank">
              <div className=" relative bg-key-sand pl-16 pr-6 my-10">
                <StaticImage src="../images/s_yaegashi.png" 
                  className="bottom-0 -left-1 w-[62px]"
                  style={{position: "absolute"}}/>
                <h4 className="font-Copperplate text-xl sm:text-3xl py-3">Twitter</h4>
                <p className="pb-6">スタートアッププラス公式Twitterです。フォローお願いします！</p>
              </div>
            </Link>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt(truncate: true)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`